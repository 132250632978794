import { createContext, useContext } from 'react';

import { TAgentPreference } from '@cloud-wave/neon-common-lib';

import { ValueOf } from 'lib/core/types';

import INCOMING_TASK_PLACEMENTS from 'lib/common/constants/incomingTaskPlacements';
import RINGTONE_OPTIONS from 'lib/common/constants/ringtoneOptions';
import WORKSPACE_LAYOUTS from 'lib/common/constants/workspaceLayouts';

import { isWindows } from 'lib/common/utils/isWindows';

const DEFAULT_INCOMING_TASK_PLACEMENT = isWindows() ? INCOMING_TASK_PLACEMENTS.TOP : INCOMING_TASK_PLACEMENTS.BOTTOM;
const DEFAULT_ENHANCED_LOGGING_STATE = false;

type TPreferencesContext = {
  state: {
    defaultWorkspaceLayout: ValueOf<typeof WORKSPACE_LAYOUTS>;
    incomingTaskPlacement: ValueOf<typeof INCOMING_TASK_PLACEMENTS>;
    ringtone: string;
    enhancedLoggingEnabled: boolean;
  };
  actions: {
    setDefaultWorkspaceLayout: (layout: ValueOf<typeof WORKSPACE_LAYOUTS>) => void;
    setIncomingTaskPlacement: (incomingTaskPlacement: ValueOf<typeof INCOMING_TASK_PLACEMENTS>) => void;
    setRingtone: (ringtone: string) => void;
    setEnhancedLoggingEnabled: (enhancedLoggingEnabled: boolean) => void;
  };
};

const Context = createContext<TPreferencesContext>({
  state: {
    incomingTaskPlacement: DEFAULT_INCOMING_TASK_PLACEMENT,
    defaultWorkspaceLayout: WORKSPACE_LAYOUTS.THREE_COLUMNS,
    ringtone: RINGTONE_OPTIONS[0].value,
    enhancedLoggingEnabled: DEFAULT_ENHANCED_LOGGING_STATE
  },
  actions: {
    setDefaultWorkspaceLayout: () => {},
    setIncomingTaskPlacement: () => {},
    setRingtone: () => {},
    setEnhancedLoggingEnabled: () => {}
  }
});

export default Context;

export const usePreferencesContext = () => useContext(Context);
