import { useHotkeys } from 'react-hotkeys-hook';

import { faEnvelope, faMessage, faSquareCheck } from '@fortawesome/pro-regular-svg-icons';
import { v4 as uuid } from 'uuid';

import { Permissions } from '@cloud-wave/neon-common-lib';
import { translate } from '@cloud-wave/neon-common-lib/app';
import { AgentEmailSignatureInsertOn } from '@cloud-wave/neon-common-lib/common';

import { useConfigContext } from 'lib/core/config';

import { useAgentPreferencesContext } from 'lib/common/contexts/AgentPreferenceContext';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import connectTypeMap from 'lib/common/contexts/ContactContext/constants/connectTypeMap';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';

import { EmailSendTypes } from 'lib/common/hooks/useEmail/api/sendEmail';

import { LogEvents, logger } from 'lib/common/components/LoggerController';
import PopoverMenu from 'lib/common/components/PopoverMenu';
import PopoverMenuItem from 'lib/common/components/PopoverMenuItem';

import connectionStates from 'lib/common/constants/connectionStates';
import connectionType from 'lib/common/constants/connectionType';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import contactTypes from 'lib/common/constants/contactTypes';

import { ConnectPermissions } from 'lib/common/types/Permissions';
import { AGENT_WORKSPACE_HOTKEYS } from 'lib/common/utils/hotkeys/hotkeyMap';

import { transferToAnyQueue } from '../../types/TaskContent';

export default function CreateTaskPopover({ anchor, onCreateTask, onSendSms }) {
  const { hasPermission } = usePermissionsContext();

  const {
    state: {
      agentPreference: { emailSignature, emailSignatureInsetOn }
    }
  } = useAgentPreferencesContext();

  const {
    actions: { addDraftTask, setSelectedTaskId }
  } = useContactContext();
  const { config } = useConfigContext();

  const onCreateEmail = () => {
    const taskId = uuid();
    const addSignature =
      emailSignatureInsetOn === AgentEmailSignatureInsertOn.All ||
      emailSignatureInsetOn === AgentEmailSignatureInsertOn.NEW;

    addDraftTask({
      status: CONTACT_STATES.DRAFT,
      type: connectTypeMap[contactTypes.EMAIL],
      connectionValue: 'New Email',
      connectionState: connectionStates.LIVE,
      connectionType: connectionType.OUTBOUND,
      taskId,
      time: new Date(),
      description: '',
      connectedAtTime: new Date(),
      email: {
        type: EmailSendTypes.NEW,
        originalEmailHtml: addSignature ? '<p/><p/>' + emailSignature : ''
      },
      connectionTimestamps: {},
      transferableQueueArns: transferToAnyQueue,
      // todo come up with a better way to avoid this bypass of contact type for drafts
      contactSnapshot: {} as any,
      contact: { contactId: taskId } as any
    });

    setSelectedTaskId(taskId);
  };

  const hasEmailPermission = hasPermission({ type: 'tenant', permission: Permissions.EMAIL });
  const canCreateTasks = !hasPermission({
    type: 'connect',
    permission: ConnectPermissions.CREATE_TASK_DISABLED
  });
  const canSendOutboundSms =
    hasPermission({ type: 'tenant', permission: Permissions.OUTBOUND_SMS }) &&
    Boolean(config?.OUTBOUND_SMS?.endpointUrl);

  useHotkeys(AGENT_WORKSPACE_HOTKEYS.ADD_TASK, () => onCreateTask(), { enabled: canCreateTasks });
  useHotkeys(AGENT_WORKSPACE_HOTKEYS.SEND_MESSAGE, () => onSendSms(), { enabled: canSendOutboundSms });

  if (!hasEmailPermission && !canCreateTasks && !canSendOutboundSms) {
    return null;
  }

  return (
    <PopoverMenu anchor={anchor} closeOnClick>
      {hasEmailPermission ? (
        <PopoverMenuItem
          text="Email"
          subtitle={`Send an email on behalf of your ${translate('Organisation')}`}
          icon={faEnvelope}
          onClick={() => {
            logger.info(LogEvents.EMAIL.DRAFT_CREATED.OPEN);
            onCreateEmail();
          }}
        />
      ) : (
        void 0
      )}
      {canCreateTasks ? (
        <PopoverMenuItem
          onClick={async () => {
            onCreateTask();
          }}
          text="Agent Task"
          subtitle="Create or schedule a new agent task"
          icon={faSquareCheck}
        />
      ) : (
        void 0
      )}
      {canSendOutboundSms ? (
        <PopoverMenuItem
          onClick={async () => {
            logger.info(LogEvents.OUTBOUND_SMS_SEND.OPEN);
            onSendSms();
          }}
          text="SMS / Text Message"
          subtitle="Send a message to a phone number"
          icon={faMessage}
        />
      ) : (
        void 0
      )}
    </PopoverMenu>
  );
}
