// sort-imports-ignore
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';

import { getFormattedStackVersion } from '@cloud-wave/neon-common-lib';

import isDevEnvironment from 'lib/common/constants/isDevEnvironment';

import requestNotificationPermission from 'lib/common/utils/requestNotificationPermission';

import 'css/app.scss';
import 'css/global.scss';
// import '@cloud-wave/cloudwave-ui-kit/style.css';

import packageJson from '../package.json';
import App from './App';
import { unregister as unregisterServiceWorker } from './serviceWorker';

export const STACK_VERSION = process.env.VITE_STACK_VERSION!;

function overrideLog(type: string) {
  const originalLog = window.console[type];

  window.console[type] = (message, ...args) => {
    const timestamp = new Date().toISOString();

    // message logged from aws connect streams contains [ERROR] string
    if (type === 'error' && typeof message === 'string' && message.includes('[ERROR]')) {
      return originalLog(`[${timestamp}] [CONNECT-ERROR]`, message);
    }

    originalLog(`[${timestamp}] [${type.toUpperCase()}] [NEONNOW]`, message, ...args);
  };
}

if (!isDevEnvironment) {
  Sentry.init({
    environment: `${process.env.VITE_DEPLOYMENT_ENVIRONMENT}-${process.env.VITE_DEPLOY_REGION_CODE}`,
    dsn: 'https://a84b9050457b4b62b18b05cf7e8f5dc1@o1243134.ingest.sentry.io/6398215',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay(), Sentry.metrics.metricsAggregatorIntegration()],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: `${packageJson.name}@${getFormattedStackVersion(STACK_VERSION).presentation}`,
    normalizeDepth: 5
  });
}

if (isDevEnvironment) {
  ['log', 'info', 'warn', 'error'].forEach(overrideLog);
}

requestNotificationPermission();

ReactDOM.render(<App />, document.getElementById('root'));

unregisterServiceWorker();
