import { ReactNode, useEffect, useRef, useState } from 'react';
import { useBeforeunload } from 'react-beforeunload';

import { Amplify, Auth } from 'aws-amplify';

import { useConfigContext } from 'lib/core/config';
import { ValueOf } from 'lib/core/types';

import { useAgentContext } from 'lib/common/contexts/AgentContext';

import { useLocalStorage } from 'lib/common/hooks/useLocalStorage';

import { LogEvents, logger } from 'lib/common/components/LoggerController';
import SignedOutOverlay from 'lib/common/components/atoms/SignedOutOverlay';

import SIGN_OUT_EVENT from 'lib/common/constants/signOutEvent';

import type TUser from 'lib/common/types/User';
import EventEmitter from 'lib/common/utils/EventEmitter';
import connectGetter from 'lib/common/utils/connectGetter';

import Context from './Context';
import AuthError from './components/AuthError';
import ConnectStreams from './components/ConnectStreams';
import LoginPage from './components/Login';
import SigningOutOverlay from './components/SigningOutOverlay';
import SIGN_OUT_TYPES from './constants/signOutTypes';
import ITokens from './types/Tokens';
import { handleSignout } from './utils/handleSignout';

type Props = {
  children: ReactNode;
};

const USERNAME_SUFFIX = '@neon.com';

function getChild({ children, loaded, user, error, signedOut, signingOut, signingOutRef }) {
  if (signedOut) {
    return <SignedOutOverlay />;
  }

  if (error) {
    return (
      <>
        {signingOut && <SigningOutOverlay type={signingOutRef?.current} />}
        <AuthError />
      </>
    );
  }

  return (
    <>
      {signingOut && <SigningOutOverlay type={signingOutRef?.current} />}
      {!user && <LoginPage />}
      {loaded && children}
    </>
  );
}

function getConnectUserID(agent: connect.Agent) {
  const agentQueues = connectGetter(agent, 'getConfiguration')?.routingProfile.queues;

  // By default, an agent is assigned a queue for transferring calls to the agent directly.
  const agentQueueArn = agentQueues?.find((q) => q.name === null)?.queueARN;

  // We get the connect user ID from the null queue ARN:
  // e.g. arn:aws:connect:ap-southeast-2:account:instance/instance-id/queue/agent/6ce04498-e7a3-44c2-a807-19a882bd6577"
  return agentQueueArn?.split('/')[4];
}

const AuthProvider = ({ children }: Props) => {
  const { config, configLoaded } = useConfigContext();
  const { agent } = useAgentContext();
  const [tokens, setTokens] = useState<ITokens | null>(null);
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [mounted, setMounted] = useState(true);
  const [email, setEmail] = useState<string>('');
  const [user, setUser] = useState<TUser | null>(null);
  const [signedOut, setSignedOut] = useState<boolean>(false);
  const [signingOut, setSigningOut] = useState<boolean>(false);
  const [connectUserId, setConnectUserId] = useState<string | undefined>();

  const signingOutRef = useRef<null | ValueOf<typeof SIGN_OUT_TYPES>>(null);

  // Allows callbacks to get the latest user to avoid stale references, eg. in connect handlers
  const userRef = useRef(user);

  const { removeStorageItem, setStorageItem } = useLocalStorage();

  const tenantID = config.TENANT_ID;

  const getUser = () => userRef?.current;

  const checkSession = async () => {
    try {
      if (loaded) {
        return;
      }

      const currentUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();

      if (mounted) {
        setTokens(JSON.parse(JSON.stringify(currentSession)));
      }

      // tenantId__username (non sso) || tenantId__username@company.com (sso)
      const usernameWithTenantId = currentUser.username.includes(USERNAME_SUFFIX)
        ? currentUser.username.split('@')[0]
        : currentUser.username;

      const username = usernameWithTenantId.split('__')[1];

      EventEmitter.emit('initUserData', username);
    } catch (e) {
      if (e == null) {
        return;
      }
      console.error('Error checking user session', e);
    }
  };

  const signOut = async (type = SIGN_OUT_TYPES.MANUAL_SIGN_OUT) => {
    if (signingOutRef?.current) {
      return;
    }

    logger.info(LogEvents.AUTH.SIGN_OUT.INITIATED, { user: user?.email });

    signingOutRef.current = type;
    setSigningOut(true);

    const connect = (window as any).getConnect();

    const responses = await Promise.allSettled([
      fetch(`${config.CONNECT_HOST}/connect/logout`, {
        credentials: 'include',
        mode: 'no-cors'
      }),
      handleSignout({ global: true })
    ]);

    const errorResponse = responses.find((r) => r.status === 'rejected');

    if (errorResponse) {
      logger.error(LogEvents.AUTH.SIGN_OUT.COMPLETED.FAIL, { error: errorResponse });
    }

    connect.core.terminate();

    navigator.sendBeacon(
      `${config.AGENT_SERVICE_URL}/agent/${config.TENANT_ID}__${sessionStorage.getItem('c_user')}/cleanup/`,
      'close'
    );

    // Cross tab method to trigger sign out across other tabs in isolated mode
    // Other instances of neon will auto sign out because they have Connect embedded
    setStorageItem(SIGN_OUT_EVENT, 'true');

    sessionStorage.clear();

    logger.info(LogEvents.AUTH.SIGN_OUT.COMPLETED.SUCCESS, { user: user?.email });

    // Don't reload the page if it's a manual sign out
    if (type === SIGN_OUT_TYPES.MANUAL_SIGN_OUT) {
      return void setSignedOut(true);
    }

    window.location.href = window.location.origin;
  };

  EventEmitter.on('onInit', async (user_) => {
    try {
      checkSession();
      if (loaded) {
        return;
      }
      signIn(user_);
    } catch (e) {
      console.error('Error refreshing token', e);
    }
  });

  EventEmitter.on('onError', (val) => {
    setError(val);
  });

  const fetch_ = async (url, options = {}, tokens_?: ITokens): Promise<Response> => {
    const headers = new Headers();
    const currentSession = await Auth.currentSession().catch(() => setSignedOut(true));
    const token = tokens_?.idToken?.jwtToken ?? currentSession?.getIdToken().getJwtToken();

    if (!token) {
      setSignedOut(true);
      return Promise.reject('no token');
    }

    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    const result = await fetch(url, { ...options, headers });

    if (!result.ok) {
      const errorBody = await result?.json?.();

      throw { ...errorBody, status: result.status };
    }

    return result;
  };

  const signIn = async (user) => {
    const username = `${tenantID}__${user.username}${!user.username.includes('@') ? USERNAME_SUFFIX : ''}`; // email address
    try {
      const challengeAnswer = user.routingProfile.queues.filter((q) => q.name === null)[0].queueARN.split('/')[4]; // connect user id
      const cognitoUser = await Auth.signIn(username);
      const currentSession = await Auth.sendCustomChallengeAnswer(cognitoUser, challengeAnswer);

      setTokens(JSON.parse(JSON.stringify(currentSession.signInUserSession)));
      logger.info(LogEvents.AUTH.SIGN_IN.SUCCESS, { username });
    } catch (error) {
      setError(true);
      console.error('Error signing in', error);
      logger.info(LogEvents.AUTH.SIGN_IN.FAIL, { username, error });
    }
  };

  if (configLoaded) {
    Amplify.configure({
      Auth: {
        region: config.COGNITO_USER_POOL_ARN.split(':')[3],
        userPoolId: config.COGNITO_USER_POOL_ARN.split('/')[1],
        userPoolWebClientId: config.COGNITO_CLIENT_ID
      }
    });
  }

  useEffect(() => {
    EventEmitter.on('userData', (user) => {
      setUser(user);
    });
  }, []);

  useEffect(() => {
    if (!agent || connectUserId) {
      return;
    }

    setConnectUserId(getConnectUserID(agent));
  }, [agent]);

  useEffect(() => {
    const getSecureConfig = async () => {
      const userInfoJson = await fetch_(
        `${config.AGENT_SERVICE_URL}/connect/${tenantID}/describe/user/?objectId=${connectUserId}`
      );
      const userInfo = await userInfoJson.json();

      // Making userInfo optional as IsolatedAuthProvider user object can be empty
      setEmail(userInfo?.User?.IdentityInfo?.Email);

      EventEmitter.emit('initUserData', sessionStorage.getItem('c_user'));
    };

    const initializeApp = async () => {
      setFetching(true);

      // If the user has previously signed out and this event exists in storage, remove it on sign in
      removeStorageItem(SIGN_OUT_EVENT);

      try {
        await getSecureConfig();

        // Logging users out after session invalid
        // @ts-ignore This exists and works but isn't in the type
        connect.core.getEventBus().subscribe(connect.EventType.AUTH_FAIL, () => {
          logger.warn(LogEvents.AUTH.SESSION_EXPIRED, { user: user?.email });

          signOut(SIGN_OUT_TYPES.AUTH_FAIL);
        });

        setLoaded(true);
      } catch (e) {
        // Better to console log all errors rather than failing silently
        console.log(e);
        setError(true);
      }
    };

    if (tokens !== null && !fetching) {
      initializeApp();
    }

    return () => {
      setMounted(false);
    };
  }, [tokens]);

  useBeforeunload((event) => {
    const contacts = connectGetter(agent, 'getContacts') || [];

    // If there are no contacts, or we are signing out, don't block reload
    if (!contacts.length || signingOutRef.current) {
      return;
    }

    // Show dialog to prevent users from refreshing the page when they have tasks
    logger.warn(LogEvents.PAGE_RELOAD_WITH_TASKS, { contacts });
    event.preventDefault();
  });

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  return (
    <Context.Provider
      value={{
        fetch_,
        loaded,
        tokens,
        email,
        signOut,
        connectUserId,
        user,
        getUser
      }}
    >
      {!signedOut && <ConnectStreams config={config} />}
      {getChild({ error, user, signedOut, signingOut, signingOutRef, children, loaded })}
    </Context.Provider>
  );
};

export default AuthProvider;
