import { ValueOf } from 'lib/core/types';

import TCustomerProfile from 'lib/common/contexts/ContactContext/types/CustomerProfile';
import THandleContactChange from 'lib/common/contexts/ContactContext/types/HandleContactChange';
import getInitialChatMessages from 'lib/common/contexts/ContactContext/utils/getInitialChatMessages';
import registerChatHandlers from 'lib/common/contexts/ContactContext/utils/registerChatHandlers';
import taskBuilder from 'lib/common/contexts/ContactContext/utils/taskBuilder/index';

import MessageStatus from 'lib/common/constants/chat/MessageStatus';
import ParticipantRole from 'lib/common/constants/chat/ParticipantRole';
import CONNECTION_STATES from 'lib/common/constants/connectionStates';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONTACT_TYPE, { TContactTypes } from 'lib/common/constants/contactTypes';

import ChatTask from 'lib/common/types/ChatTask';
import TChatTask from 'lib/common/types/ChatTask';
import Task from 'lib/common/types/Task';
import { THydratedChat } from 'lib/common/types/chat/HydratedChat';

import { TChatChanges } from '../../../types/ChatChanges';
import getUniqueAndSortedChatMessages from '../../getUniqueAndSortedChatMessages';

const buildMessageStatus = (existingMessage, newChat) => {
  const { messageStatus, message: newMessage } = newChat;

  const isFailedMessage = existingMessage?.messageStatus === MessageStatus.FAILED;
  // If latest message is read or if the last message is coming from customer then all messages are read except failed messages
  const areAllAgentMessagesRead =
    newMessage?.ParticipantRole === ParticipantRole.CUSTOMER || messageStatus?.status === MessageStatus.READ;

  if (existingMessage.ParticipantRole !== ParticipantRole.AGENT) {
    return void 0;
  }

  if (existingMessage.messageStatus && areAllAgentMessagesRead && !isFailedMessage) {
    return {
      messageStatus: MessageStatus.READ
    };
  }

  return {
    messageStatus: existingMessage.messageStatus
  };
};

export default async function updatedChatTask({
  newChat,
  existingTask,
  hydratedChat,
  getSelectedTaskId,
  icon,
  handleContactChange,
  updatedContact,
  taskProps,
  type,
  status,
  connectionState,
  profile
}: {
  newChat?: TChatChanges;
  existingTask: TChatTask;
  hydratedChat?: THydratedChat;
  getSelectedTaskId: () => string | undefined;
  icon: string;
  handleContactChange: THandleContactChange;
  updatedContact: connect.Contact;
  taskProps: Partial<Task>;
  type: TContactTypes;
  status?: ValueOf<typeof CONTACT_STATES>;
  connectionState?: ValueOf<typeof CONNECTION_STATES>;
  profile?: TCustomerProfile;
}) {
  const updatedTask = taskBuilder.updatedBaseTask({
    existingTask,
    updatedContact,
    taskProps,
    type,
    status,
    connectionState,
    profile
  });

  // Need to register for chat messages after task is connected
  if (status === CONTACT_STATES.CONNECTED && type === CONTACT_TYPE.CHAT) {
    const chatSession = (existingTask as ChatTask).chatSession;
    registerChatHandlers({
      session: chatSession,
      taskId: existingTask.taskId,
      handleContactChange,
      getSelectedTaskId,
      profile: existingTask.profile,
      icon
    });
    const { messages, nextToken } = await getInitialChatMessages(chatSession);

    return {
      ...updatedTask,
      messages,
      nextToken
    };
  }

  if (!newChat && !hydratedChat) {
    return updatedTask as ChatTask;
  }

  if (hydratedChat) {
    const hydratedMessages = hydratedChat?.messages || [];
    const newMessages = getUniqueAndSortedChatMessages([...hydratedMessages, ...existingTask.messages]);

    return {
      ...updatedTask,
      messages: newMessages,
      nextToken: hydratedChat?.nextToken
    } as ChatTask;
  }

  const { message: newMessage, customerTyping, unreadMessage, messageStatus } = newChat || {};

  const allMessages = newMessage ? [...(existingTask.messages || []), newMessage] : existingTask.messages;
  const newMessages = allMessages.map((message) => {
    if (message.Id === messageStatus?.messageId && message.ParticipantRole === ParticipantRole.AGENT) {
      return {
        ...message,
        messageStatus: messageStatus.status,
        ...(messageStatus.errorCode ? { errorCode: messageStatus.errorCode } : {})
      };
    }

    return {
      ...message,
      ...buildMessageStatus(message, newChat)
    };
  });

  return {
    ...updatedTask,
    messages: newMessages,
    customerTyping: typeof customerTyping !== 'undefined' ? customerTyping : existingTask.customerTyping,
    unreadMessage: unreadMessage ? unreadMessage : false
  } as ChatTask;
}
