import { ReactElement } from 'react';

import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { translate } from '@cloud-wave/neon-common-lib/app';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import Icon from '../Icon';

import styles from './segmented-controls.module.scss';

export type TSegmentedControlsOption = { icon?: IconDefinition | ReactElement } & (
  | { label: string; value?: string }
  | { label?: string; value: string }
);
/* this construct specifies that at least one of these properties is required,
 * but both may be provided. if this is a common enough pattern elsewhere this
 * should be extracted to a common utility type.
 */

export type TControlledSegmentedControls = {
  options: TSegmentedControlsOption[];
  onSelect?: ((option: string) => void) | ((option: string[]) => void);
  className?: string;
  square?: boolean;
  inverse?: boolean;
  small?: boolean;
  multi?: boolean;
  multiMinimum?: number;
  selectedIndices?: number | number[];
  setSelectedIndices?: (indices: number[]) => void;
  ariaLabel?: string;
  disabled?: boolean;
};

function SegmentedControlsOption({
  label,
  icon,
  value,
  selected,
  onClick,
  multi,
  small,
  disabled = false
}: TSegmentedControlsOption & {
  selected: boolean;
  disabled?: boolean;
  onClick?: (option: any) => void;
  multi: boolean;
  small: boolean;
}) {
  const organisationSpelling = translate('Organisation');
  const translatedLabel = label?.replace('Organisation', organisationSpelling);
  return (
    <button
      aria-label={label || value}
      role="tab"
      aria-selected={selected}
      className={cx(styles['segmented-controls__option'], {
        [styles['segmented-controls__option--selected']]: selected,
        [styles['segmented-controls__option--small']]: small,
        [styles['segmented-controls__option--disabled']]: disabled,
        [styles['segmented-controls__option--icon-only']]: !label && icon
      })}
      onClick={selected && !multi ? void 0 : onClick}
      data-testid={`segmented-control-option-${typeof label === 'string' ? label.toLowerCase() : value}`}
    >
      {icon && 'iconName' in icon ? <Icon className={cx({ 'mr-10': label })} icon={icon} size={17} /> : icon}
      {translatedLabel}
    </button>
  );
}

export default function ControlledSegmentedControls({
  options,
  onSelect,
  className,
  square,
  inverse,
  small = false,
  multi = false,
  multiMinimum = 0,
  selectedIndices = [],
  setSelectedIndices,
  ariaLabel,
  disabled
}: TControlledSegmentedControls) {
  const { isSoftphone } = useLayout();
  const selectedIndicesArray = typeof selectedIndices === 'number' ? [selectedIndices] : selectedIndices;
  const atMinimumSelections =
    (multi && selectedIndicesArray.length <= multiMinimum) || (!multi && selectedIndicesArray.length);

  const getKeyFromOption = (index: number) => (options[index].value ?? options[index].label) as string;

  const handleSingleSelect = (index: number) => () => {
    setSelectedIndices?.([index]);

    //@ts-expect-error typing is tricky here
    return onSelect?.(getKeyFromOption(index));
  };

  const multiUnselectItem = (index: number) => {
    const newState = [...selectedIndicesArray, index];
    //@ts-expect-error typing is tricky here
    onSelect?.(newState.map(getKeyFromOption));
    setSelectedIndices?.(newState);
  };

  const multiSelectItem = (index: number) => {
    const newState = selectedIndicesArray.filter((i: number) => i !== index);
    //@ts-expect-error typing is tricky here
    onSelect?.(newState.map(getKeyFromOption));
    setSelectedIndices?.(newState);
  };

  const handleMultiSelect = (index: number) => () => {
    const itemSelected = selectedIndicesArray.includes(index);

    if (!itemSelected) {
      return multiUnselectItem(index);
    }

    if (!atMinimumSelections) {
      return multiSelectItem(index);
    }
  };

  return (
    <div
      role="tablist"
      aria-label={ariaLabel}
      className={cx(
        styles['segmented-controls'],
        {
          [styles['segmented-controls--square']]: square,
          [styles['segmented-controls--inverse']]: inverse,
          [styles['segmented-controls--locked']]: atMinimumSelections,
          [styles['segmented-controls--multi']]: multi,
          [styles['segmented-controls--softphone']]: isSoftphone
        },
        className
      )}
    >
      {options.map((option, index) => (
        <SegmentedControlsOption
          disabled={disabled}
          key={option.value ?? option.label}
          selected={selectedIndicesArray.includes(index)}
          onClick={multi ? handleMultiSelect(index) : handleSingleSelect(index)}
          multi={multi}
          small={small}
          {...option}
        />
      ))}
    </div>
  );
}
