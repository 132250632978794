import { createContext, useContext } from 'react';

import { TAgentPreference, TUpdateAgentPreferenceBodySchema } from '@cloud-wave/neon-common-lib';

import agentDefaultPreference from './constants/agentDefaultPreference';

export type TPreferencesContext = {
  state: {
    preferenceModalOpen: boolean;
    agentPreference: TAgentPreference;
    preferredDateFormat: string;
  };
  actions: {
    setPreferenceModalOpen: (openValue: boolean) => void;
    updatePreference: (agentPreference: TUpdateAgentPreferenceBodySchema) => Promise<void>;
  };
};

const Context = createContext<TPreferencesContext>({
  state: {
    preferenceModalOpen: false,
    agentPreference: agentDefaultPreference,
    preferredDateFormat: 'dd/MM/yyyy'
  },
  actions: {
    setPreferenceModalOpen: () => {},
    updatePreference: async () => {}
  }
});

export default Context;

export const useAgentPreferencesContext = () => useContext(Context);
