import CONTACT_STATES from 'lib/common/constants/contactStates';

import { TTaskEvent } from '../../types/TaskEvent';

// The code introduced in this util is to handle some of the weird cases of the order of events when they are received from connect as follows:
// Problem-1: when agents have slow network speed the way events get handled cause the accepted event to get registered after the connected event
// Problem-2: when agents have slow machines the order of events get out of order and cause the accepted event to get send from connect after the connected event
// For reference, see https://cloud-wave.atlassian.net/browse/NEON-4515
export default function handleTaskEvent({
  tasksEventRef,
  changes
}: {
  tasksEventRef: React.MutableRefObject<TTaskEvent[]>;
  changes: Record<string, any>;
}) {
  if (changes.isDestroy) {
    return tasksEventRef.current.filter((event) => event.contactId !== changes.contact?.contactId);
  }

  if (!changes.eventTimestamp) {
    return tasksEventRef.current;
  }

  const changedTaskIndex = tasksEventRef.current.findIndex((event) => event.contactId === changes.contact?.contactId);
  const changedTask = tasksEventRef.current[changedTaskIndex];

  if (!changedTask) {
    return [
      ...tasksEventRef.current,
      {
        contactId: changes.contact?.contactId,
        status: changes.status,
        eventTimestamp: changes.eventTimestamp
      }
    ];
  }

  if (changedTask.status === CONTACT_STATES.CONNECTED) {
    return tasksEventRef.current;
  }

  return tasksEventRef.current.toSpliced(changedTaskIndex, 1, {
    contactId: changes.contact?.contactId,
    status: changes.status,
    eventTimestamp: changes.eventTimestamp
  });
}
