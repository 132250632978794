import { TOrganisationContact, TPersonalContact } from '@cloud-wave/neon-common-lib';
import { translate } from '@cloud-wave/neon-common-lib/app';

export enum ContactActions {
  CREATE = 'creating',
  EDIT = 'editing',
  DELETE = 'deleting'
}

export const ContactTypeLabels = {
  MICROSOFT_ENTRA_ID: 'Teams Contact',
  EXTERNAL_DIRECTORY: 'External Contact',
  ORGANISATION: `${translate('Organisation')} Contact`,
  PERSONAL: 'Personal Contact',
  AGENT: 'Agent'
} as const;

export type TInternalContacts = TPersonalContact | TOrganisationContact;
