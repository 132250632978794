import { useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { GridJustification } from '@material-ui/core/Grid';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { DatePicker } from 'lib/common/components/Input';

import { useAgentPreferencesContext } from '../../contexts/AgentPreferenceContext';

const DEFAULT_RANGE = 3; // 3 DAYS DEFAULT DATE RANGE
const MAX_RANGE = 7; // 7 DAYS MAX DATE RANGE

function getAdjustedDate(date, adjustment) {
  return new Date(date.setDate(date.getDate() + adjustment));
}

function getEndDateFromStartDate({
  updatedStartDate,
  currentEndDate
}: {
  updatedStartDate: Date;
  currentEndDate?: Date;
}) {
  const today = new Date();
  const endDate = currentEndDate ? new Date(currentEndDate) : currentEndDate;
  const adjustedEndDate = getAdjustedDate(new Date(updatedStartDate), MAX_RANGE);

  if (!endDate || adjustedEndDate < endDate) {
    return adjustedEndDate > today ? today : adjustedEndDate;
  }

  return endDate > today ? today : endDate;
}

function getStartDateFromEndDate({
  currentStartDate,
  updatedEndDate
}: {
  currentStartDate: Date;
  updatedEndDate: Date;
}) {
  const adjustedStartDate = getAdjustedDate(new Date(updatedEndDate), -MAX_RANGE);
  const startDate = new Date(currentStartDate);

  return adjustedStartDate > startDate ? adjustedStartDate : startDate;
}

export default function DateSelector({
  onDateChange,
  defaultStartDate = getAdjustedDate(new Date(), -DEFAULT_RANGE),
  defaultEndDate
}: {
  defaultStartDate?: Date;
  defaultEndDate?: Date;
  onDateChange: (any) => void;
  justifyDirection?: GridJustification;
}) {
  const {
    state: { preferredDateFormat }
  } = useAgentPreferencesContext();
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate || getEndDateFromStartDate({ updatedStartDate: startDate }));

  const handleStartDateChange = (date) => {
    if (!date) {
      return;
    }

    const newEndDate = getEndDateFromStartDate({ updatedStartDate: date, currentEndDate: endDate });

    setStartDate(date);
    setEndDate(newEndDate);

    onDateChange({ start: date, end: newEndDate });
  };

  const handleEndDateChange = (date) => {
    if (!date) {
      return;
    }

    const newStartDate = getStartDateFromEndDate({ updatedEndDate: date, currentStartDate: startDate });

    setEndDate(date);
    setStartDate(newStartDate);

    onDateChange({ start: newStartDate, end: date });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="next-to-20">
        <DatePicker
          id="start-date-picker-dialog"
          label="From"
          format={preferredDateFormat}
          value={startDate}
          onChange={handleStartDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          maxDate={endDate}
        />
        <DatePicker
          id="end-date-picker-dialog"
          label="To"
          format={preferredDateFormat}
          value={endDate}
          onChange={handleEndDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          minDate={startDate}
          maxDate={new Date()}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
}
