import { faBuilding, faMobileButton } from '@fortawesome/pro-regular-svg-icons';

import { ContactType } from '@cloud-wave/neon-common-lib';
import { translate } from '@cloud-wave/neon-common-lib/app';

import ColouredPanel from 'lib/common/components/atoms/ColouredPanel';
import DescriptivePills from 'lib/common/components/atoms/DescriptivePills';

import getAllowedType from '../utils/getAllowedType';

export default function DirectoryContactType({ type, setType, isAdmin, hasPersonalContactPermission }) {
  const allowedType = getAllowedType(isAdmin, hasPersonalContactPermission);

  if (allowedType === ContactType.PERSONAL) {
    return <ColouredPanel>This will be a personal contact, only visible to you in your directory.</ColouredPanel>;
  }

  if (allowedType === ContactType.ORGANISATION) {
    return (
      <ColouredPanel>
        This will be an {translate('organisation')} contact, visible to everyone within your contact{' '}
        {translate('centre')}.
      </ColouredPanel>
    );
  }

  return (
    <DescriptivePills
      ariaLabel="Contact type"
      pills={[
        {
          heading: 'Personal',
          selected: type === ContactType.PERSONAL,
          description: 'Only visible to you in your directory.',
          icon: faMobileButton,
          onClick: () => setType(ContactType.PERSONAL)
        },
        {
          heading: translate('Organisation'),
          selected: type === ContactType.ORGANISATION,
          description: `Visible to everyone within your contact ${translate('centre')}.`,
          icon: faBuilding,
          onClick: () => setType(ContactType.ORGANISATION)
        }
      ]}
    />
  );
}
