import { KeysOfType } from 'lib/core/types';

type TGettableEntity = connect.Contact | connect.BaseConnection | connect.Agent | connect.ChatSession;
type TGetterKeys<T> = keyof Pick<T, keyof T & `${'get' | 'is'}${string}`>;
type TCallableKeys<T> = KeysOfType<T, (...args: any[]) => any>;
type TGetters<T> = TGetterKeys<T> & TCallableKeys<T>;

// Note: payload will always be the first argument, if connect-streams methods are ever introduced that
// require multiple positional arguments this will need to be revised.
type TPayload<T, A extends keyof T> = T[A] extends (...args: infer P) => unknown ? P[0] : never;
type TReturnType<T, A extends keyof T> = T[A] extends (...args: any) => infer R ? R : never;

function connectGetter<T extends TGettableEntity, A extends TGetters<T>>(
  entity: T | null | undefined,
  action: A,
  payload?: TPayload<T, A>,
  retries = 3,
  errorMsg?: string
): TReturnType<T, A> | undefined {
  if (!entity) {
    return;
  }

  try {
    if (entity instanceof connect.Contact) {
      const agentContacts = connectGetter(new connect.Agent(), 'getContacts') || [];

      const contactExists = agentContacts.some(
        (existingContact: connect.Contact) => entity.contactId === existingContact.contactId
      );

      if (!contactExists) {
        return undefined;
      }
    }
  } catch (e) {
    // connect.Contact is sometimes mocked or unavailable in tests, causing the
    // instanceof check to fail.
    if (e instanceof TypeError && process.env.NODE_ENV === 'test') {
    } else {
      throw e;
    }
  }

  try {
    // eslint-disable-next-line @typescript-eslint/ban-types
    return (entity[action] as unknown as Function)(payload);
  } catch (e: any) {
    // connectGetter not fully mocked so this reduces noise
    if (process.env.NODE_ENV === 'test') {
      return;
    }

    if (retries === 0) {
      console.error(`${entity}`, `${action}`, e);
      return;
    }

    return connectGetter(entity, action, payload, retries - 1, errorMsg);
  }
}

export default connectGetter;
