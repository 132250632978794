import { faClock, faComment, faPhoneArrowDown, faPhoneArrowUpRight } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import { format } from 'date-fns';

import useInteractionHistory from 'lib/common/api/hooks/useInteractionHistory';
import usePagination from 'lib/common/hooks/usePagination';
import useSort from 'lib/common/hooks/useSort';

import ClickToCall from 'lib/common/components/ClickToCall';
import EmptyPlaceholder from 'lib/common/components/EmptyPlaceholder';
import Icon from 'lib/common/components/Icon';
import Loader from 'lib/common/components/Loader';
import Text from 'lib/common/components/Text';

import { useAgentPreferencesContext } from '../../../common/contexts/AgentPreferenceContext';
import Header from './components/Header';

import styles from './interaction-history.module.scss';

const CALL_ICONS = {
  inbound: faPhoneArrowDown,
  outbound: faPhoneArrowUpRight
};

export default function InteractionHistory() {
  const {
    state: { preferredDateFormat }
  } = useAgentPreferencesContext();
  const { onDateChange, loaded, error, historyData, startDate, endDate } = useInteractionHistory();
  const {
    data: sortedHistory,
    setSort,
    setSortDirection,
    currentSort,
    currentSortDirection
  } = useSort({ data: historyData, defaultSortKey: 'timestamp' });
  const { onSelectPage, data: history, currentPage, pages } = usePagination({ data: sortedHistory });

  return (
    <>
      <div className={styles['softphone-interaction-history']}>
        <Header
          onDateChange={onDateChange}
          onSelectPage={onSelectPage}
          currentPage={currentPage}
          pages={pages}
          setSort={setSort}
          setSortDirection={setSortDirection}
          startDate={startDate}
          endDate={endDate}
          currentSort={currentSort}
          currentSortDirection={currentSortDirection}
        />
        {!loaded && <Loader className={cx(styles['softphone-interaction-history__loader'], 'relative small')} />}
        {(!history || !history.length) && loaded && (
          <EmptyPlaceholder
            subText="You'll see interactions in here when you accept some tasks."
            icon={faClock}
            isError={error}
          />
        )}
        {loaded &&
          history.map((historyItem: any) => (
            <div key={historyItem.contactId} className={styles['softphone-interaction-history__item']}>
              <div className={styles['softphone-interaction-history__item__title']}>
                <Text bold ellipsis htmlElement="h2">
                  {historyItem.talkedTo}
                </Text>
                <Text className={styles['softphone-interaction-history__item__title__date']} type="label">
                  {format(new Date(historyItem.timestamp), `${preferredDateFormat} - HH:mm`)}
                </Text>
              </div>
              <div className={styles['softphone-interaction-history__item__entry']}>
                <Icon
                  className="mr-10"
                  size={15}
                  icon={historyItem.phoneNumber ? CALL_ICONS[historyItem.direction] : faComment}
                />
                {historyItem.phoneNumber ? <ClickToCall>{historyItem.phoneNumber}</ClickToCall> : 'Chat'}
              </div>
            </div>
          ))}
      </div>
    </>
  );
}
