import { useState } from 'react';

import cx from 'classnames';

import { useConfigContext } from 'lib/core/config';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import ConnectApp from 'lib/common/components/atoms/ConnectApp';
import Frame from 'lib/common/components/atoms/Frame';

import TTask from 'lib/common/types/Task';
import getInstanceUrl from 'lib/common/utils/getInstanceUrl';

import styles from '../styles/modules.module.scss';

const LOADER_SIZE = 60;

export default function ModulesIFrame({
  selected,
  selectedTask,
  id,
  source,
  title
}: {
  selected: boolean;
  selectedTask?: TTask;
  id: string;
  source: string;
  title: string;
}) {
  const { isSoftphone } = useLayout();
  const { config } = useConfigContext();
  const instanceUrl = getInstanceUrl(config);

  // This will be the value of the first task in the list and won't change unless all tasks are cleared
  const [initialTaskId] = useState(selectedTask?.contact.contactId);

  return (
    <div className={cx(styles['modules__content'], { hide: !selected })}>
      {source.includes(instanceUrl) ? (
        <ConnectApp title={title} source={source} instanceUrl={instanceUrl} />
      ) : (
        <Frame
          title={`${title} module`}
          allow="clipboard-write; camera; microphone; display-capture"
          loaderSize={isSoftphone ? void 0 : LOADER_SIZE}
          className={cx(styles['modules__content'])}
          source={`${source}?initial-task-contactid=${initialTaskId}`}
          id={id}
        />
      )}
    </div>
  );
}
