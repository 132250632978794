import CONTACT_STATES from 'lib/common/constants/contactStates';

export default function isOutdatedTaskEvent({ changes, tasksEventRef }) {
  const task = tasksEventRef.current.find((event) => event.contactId === changes.contact?.contactId);
  const isOutdatedEvent =
    changes.eventTimestamp && task?.eventTimestamp && task.eventTimestamp > changes.eventTimestamp;
  const isAlreadyConnected = task?.status === CONTACT_STATES.CONNECTED && changes.status === CONTACT_STATES.ACCEPTED;

  return isOutdatedEvent || isAlreadyConnected;
}
