import { format } from 'date-fns';

import { getDateFormat } from '@cloud-wave/neon-common-lib/app';

import getUserName from './getUserName';

export default function getScheduleCallbackDescription(connectionValue, profile, connectedAt, user) {
  const userName = getUserName({ ...profile, fallback: '' });
  const displayUserName = userName ? `(${userName})` : '';
  const connectedAtTime = format(connectedAt || new Date(), 'h:mm aa');
  const connectedAtDate = format(connectedAt || new Date(), getDateFormat(navigator.language));
  const agentName = getUserName(user);

  return `${connectionValue} ${displayUserName}.\n\nOriginally contacted us at ${connectedAtTime} on ${connectedAtDate} and talked to Agent ${agentName}.`;
}
